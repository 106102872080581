import { t } from "@lingui/macro";
import { CategoryPriceType } from "../dto/inventory";
import { LeadStatus, LeadStatusV2 } from "../dto/leads";

export const getCategoryPriceTypeTranslated = (
  priceType: CategoryPriceType
): string => {
  switch (priceType) {
    case CategoryPriceType.PER_DAY:
      return t`Per day`;
    case CategoryPriceType.PER_HOUR:
      return t`Per hour`;
    default:
      return "";
  }
};

export const getPeriodTranslated = (period: string): string => {
  switch (period) {
    case "hourly":
      return t`Hourly`;
    case "daily":
      return t`Daily`;
    case "weekly":
      return t`Weekly`;
    case "monthly":
      return t`Monthly`;
    default:
      return "";
  }
};

export const getLeadStatusV2 = (): LeadStatusV2[] => {
  return [
    { value: "received", label: t`Received` },
    { value: "contacted", label: t`Contacted` },
    { value: "budgeted", label: t`Budgeted` },
    { value: "accepted", label: t`Accepted` },
    { value: "active", label: t`Active` },
    { value: "won", label: t`Won` },
    { value: "finished", label: t`Finished` },
    { value: "returned", label: t`Returned` },
    { value: "lost", label: t`Lost` },
  ];
};

export const getLeadStatusTranslated = (status: string): string => {
  switch (status) {
    case "received":
      return t`Received`;
    case "contacted":
      return t`Contacted`;
    case "budgeted":
      return t`Budgeted`;
    case "accepted":
      return t`Accepted`;
    case "active":
      return t`Active`;
    case "won":
      return t`Won`;
    case "finished":
      return t`Finished`;
    case "returned":
      return t`Returned`;
    case "lost":
      return t`Lost`;
    default:
      return status;
  }
};

export const getScheduleStatusTranslated = (status: string): string => {
  switch (status) {
    case "active":
      return t`Active`;
    case "on_rent":
      return t`Rented`;
    case "finished":
      return t`Finished`;
    case "returned":
      return t`Returned`;
    case "delivery_pending":
      return t`Delivery pending`;
    default:
      return "";
  }
};

export const getLeadKindTranslated = (kind?: string): string => {
  switch (kind) {
    case "sale":
      return t`Sale`;
    case "lease":
      return t`Lease`;
    case "specific_sale":
      return t`Specific Sale`;
    case "sale_without_category":
      return t`Generic Sale`;
    default:
      return "";
  }
};

export const getPeriodKindTranslated = (kind?: string): string => {
  switch (kind) {
    case "base":
      return t`Base`;
    case "other":
      return t`Other`;
    default:
      return t`Extension`;
  }
};
